import './ContactInfo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function ContactInfo() {
	return (
		<ul className="contact-info">
			<li>
				<FontAwesomeIcon icon={solid('location-dot')} />
				<address>Your address here 00 - 00000, Venice - Italy</address>
			</li>
			<li>
				<FontAwesomeIcon icon={solid('phone')} />
				<a href="tel:">+39 321 123456</a>
			</li>             
			<li>
				<FontAwesomeIcon icon={solid('envelope')} />
				<a href="mailto:">your@email.com</a>
			</li>
			<li>
				<a href="#">
					<FontAwesomeIcon icon={brands('facebook-f')} />
				</a>
				<a href="#">
					<FontAwesomeIcon icon={brands('instagram')} />
				</a>
				<a href="#">
					<FontAwesomeIcon icon={brands('x-twitter')} />
				</a>
				<a href="#">
					<FontAwesomeIcon icon={brands('youtube')} />
				</a>
			</li>
		</ul>
	);
}
