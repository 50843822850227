// galleries
import image1 from '../imgs/galleries/1.webp';
import image2 from '../imgs/galleries/2.webp';
import image3 from '../imgs/galleries/3.webp';
import image4 from '../imgs/galleries/4.webp';
import image5 from '../imgs/galleries/5.webp';
import image6 from '../imgs/galleries/6.webp';
import image7 from '../imgs/galleries/7.webp';
import image8 from '../imgs/galleries/8.webp';
import image9 from '../imgs/galleries/9.webp';
import image10 from '../imgs/galleries/10.webp';
import image11 from '../imgs/galleries/11.webp';
import image12 from '../imgs/galleries/12.webp';
import image13 from '../imgs/galleries/13.webp';
import image14 from '../imgs/galleries/14.webp';
import image15 from '../imgs/galleries/15.webp';
import image16 from '../imgs/galleries/16.webp';
import image17 from '../imgs/galleries/17.webp';
import image18 from '../imgs/galleries/18.webp';
import image19 from '../imgs/galleries/19.webp';
import image20 from '../imgs/galleries/20.webp';


export default [
	{
		id: 'macro',
		name: 'Macro',
		images: [
			{
				src: image16,
				alt: 'sled photographer template'
			},
			{
				src: image2,
				alt: 'sled photographer template'
			},
			{
				src: image3,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image7,
				alt: 'sled photographer template'
			},
			{
				src: image4,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image20,
				alt: 'sled photographer template'
			},
			{
				src: image17,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image18,
				x2: true,
				alt: 'sled photographer template'
			},
		]
	},
	{
		id: 'sky-and-star',
		name: 'Sky and star',
		images: [
			{
				src: image5,
				alt: 'sled photographer template'
			},
			{
				src: image8,
				alt: 'sled photographer template'
			},
			{
				src: image11,
				alt: 'sled photographer template'
			},
			{
				src: image12,
				alt: 'sled photographer template'
			},
			{
				src: image19,
				alt: 'sled photographer template'
			},
			{
				src: image15,
				alt: 'sled photographer template'
			},
			{
				src: image13,
				x2: true,
				alt: 'sled photographer template'
			},
		]
	},
	{
		id: 'animals',
		name: 'Animals',
		images: [
			{
				src: image2,
				alt: 'sled photographer template'
			},
			{
				src: image1,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image10,
				alt: 'sled photographer template'
			},
			{
				src: image3,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image4,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image6,
				alt: 'sled photographer template'
			},
			{
				src: image9,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image16,
				alt: 'sled photographer template'
			},
		]
	},
	{
		id: 'various',
		name: 'Various',
		images: [   
			{
				src: image4,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image5,
				alt: 'sled photographer template'
			},
			{
				src: image6,
				alt: 'sled photographer template'
			},
			{
				src: image7,
				alt: 'sled photographer template'
			},
			{
				src: image8,
				alt: 'sled photographer template'
			},
			{
				src: image9,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image10,
				alt: 'sled photographer template'
			},
			{
				src: image11,
				alt: 'sled photographer template'
			},
			{
				src: image12,
				alt: 'sled photographer template'
			},
			{
				src: image14,
				alt: 'sled photographer template'
			},
			{
				src: image13,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image15,
				alt: 'sled photographer template'
			},
			{
				src: image16,
				alt: 'sled photographer template'
			},
			{
				src: image17,
				x2: true,
				alt: 'sled photographer template'
			},
			{
				src: image19,
				alt: 'sled photographer template'
			},
			{
				src: image20,
				alt: 'sled photographer template'
			}
		]
	}
];