import './Gallery.css';
import { motion } from 'framer-motion';
import { useState } from 'react';
import LightboxModal from '../components/LightboxModal';

export default function Gallery({ imgs }) {
   const [clickedImg, setClickedImg] = useState(null),
         [currentIdx, setCurrentIdx] = useState(null),
         imgsLength = imgs.length;

   const handleOpen = (img, idx) => {
      setClickedImg(img);
      setCurrentIdx(idx);
   };
   
   const handleNext = () => {
      if(currentIdx + 1 >= imgsLength) {
         setCurrentIdx(0);
         setClickedImg(imgs[0]);
         return;
      }

      const newIdx = currentIdx + 1;
      setCurrentIdx(newIdx);
      setClickedImg(imgs[newIdx]);
   };
  
   const handlePrev = () => {
      if(currentIdx === 0) {
         const newIdx = imgsLength - 1;
         setCurrentIdx(newIdx);
         setClickedImg(imgs[newIdx]);
         return;
      }
      
      const newIdx = currentIdx - 1;
      setCurrentIdx(newIdx);
      setClickedImg(imgs[newIdx]);
   };

   const handleClose = e => e.target.classList.contains('open') ? setClickedImg(null) : null;
  
   return (
      <>
         <motion.section
            className="gallery-container"

            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: .8, ease: 'easeInOut' }}
            exit={{ opacity: 0, y: '100%' }}
         >

				{imgs.map((img, idx) =>
					<motion.img
						key={idx}
						src={img.src}
						alt={img.alt + ' - Gallery sled photographer react template'}
						className={img.x2 === true ? 'span2' : null}
						onClick={() => handleOpen(img, idx)}

						initial={{ opacity: 0, y: '100%' }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: .8, ease: 'easeInOut' }}
						exit={{ opacity: 0, y: '100%' }}
					/>
				)}

				{clickedImg && (
					<LightboxModal
						clickedImg={clickedImg}
						handleClose={handleClose}
						handleNext={handleNext}
						handlePrev={handlePrev}
					/>
				)}
               
         </motion.section>
         
         {clickedImg && (
            <LightboxModal
               clickedImg={clickedImg}
               handleClose={handleClose}
               handleNext={handleNext}
               handlePrev={handlePrev}
            />
         )}
      </>
   );
}