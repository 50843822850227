import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import faqs from '../data/faqs.js';
import bgfaq from '../imgs/bg/bg-faqs.webp';

import HeaderPage from '../components/HeaderPage';
import Faq from '../components/Faq';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';


export default function Faqs() {
	return (
		<>
			<Helmet>
				<title>Faqs - Sled Photographer Demo &bull; React Multipurpose Template</title>
				<meta name="keywords" content="Photographer" />
			</Helmet>

			<HeaderPage
				bg={bgfaq}
				pageName={'Faqs'}
				subtitle={'Frequent questions'}
				icon={<FontAwesomeIcon icon={solid('question')} className="badge-icon" />}
			/>

			<motion.main
				className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="mb-2">
					<h1 className="title2 txt-center">Everything you need to know!</h1>
					<h2 className="title3 subtitle txt-center mb-2">Answers to your doubts</h2>

					{faqs.map((faq, idx) =>
						<Faq
							key={idx}
							question={faq.question}
							answer={faq.answer}
						/>
					)}
				</section>

				<CallToAction
					title={<>Discover all my <span className="txt-secondary">courses and workshops</span>!</>}
					text="Learn from professional photographers and stay up to date on the latest photography features and techniques"
					toPage="/workshop"
					label="DISCOVER NOW!"
				/>
			</motion.main>

			<Footer />
		</>
	);
}