// workshops
import img1 from '../imgs/workshop/1.webp';
import img2 from '../imgs/workshop/2.webp';
import img3 from '../imgs/workshop/3.webp';
import img4 from '../imgs/workshop/4.webp';
import img5 from '../imgs/workshop/5.webp';
import img6 from '../imgs/workshop/6.webp';


export default [
   {
      id: 'ireland',
      src: img1,
      name: 'Ireland',
      date: '10 september 2024',
      text: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
   },
   {
      id: 'tropical-forest',
      src: img2,
      name: 'Tropical forest',
      date: '25 June 2023',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
   },
   {
      id: 'paris',
      src: img5,
      name : 'Paris',
      date: '17 June 2021',
		soldout: true,
      text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
   },
   {
      id: 'medieval-castles',
      src: img3,
      name: 'Medieval Castles',
      date: '29 May 2022',
		soldout: true,
      text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
   },
   {
      id: 'africa-tour',
      src: img4,
      name : 'Africa tour',
      date: '3 December 2021',
      text: 'Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
   },
   {
      id: 'italy',
      src: img6,
      name : 'Italy',
      date: '12 January 2021',
		soldout: true,
      text: 'Sed ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.',
   }
];
