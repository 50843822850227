import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import HeaderPage from '../components/HeaderPage';
import ContactForm from '../components/ContactForm';
import ContactInfo from '../components/ContactInfo';
import Review from '../components/Review';
import Footer from '../components/Footer';

import bgcontact  from '../imgs/bg/bg-contact.webp';
import review2  from '../imgs/reviews/review2.jpg';

export default function ContactMe() {
   return (
      <>
         <Helmet>
				<title>Contact Me - Sled Photographer Demo &bull; React Multipurpose Template</title>
				<meta name="keywords" content="Photographer" />
			</Helmet>

			<HeaderPage
				bg={bgcontact}
				pageName={'Contact Me'}
				subtitle={'get in touch'}
				icon={<FontAwesomeIcon icon={solid('paper-plane')} className="badge-icon" />}
			/>

			<motion.main
            className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
         >
				<section className="txt-center">
					<h2>Leave me a message</h2>
					<p className="title3 subtitle mb-2">Write to me or book your service!</p>

					<p className="mb-2">Fill out the form below to request information or services!</p>

               <div className="grid gtc-2-1 txt-left mb-2">
                  <ContactForm />
   
                  <div>
                     <ContactInfo />

                     <Review
                        img={review2 }
                        fullName="Elisabeth Souwn"
                        role="Artist"
                        stars={5}
                        review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                     />
                  </div>
               </div>
            </section>

            <section>
               <iframe className="map" title="Maps" src="https://www.google.com/maps?q=Venice&t=&z=11&ie=UTF8&iwloc=&output=embed" />
            </section>
			</motion.main>

			<Footer />
      </>
   );
}