import './Galleries.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import galleries from '../data/galleries.js';
import faqs from '../data/faqs';

import HeaderPage from '../components/HeaderPage';
import Faq from '../components/Faq';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';


export default function Galleries() {
	return (
		<>
			<Helmet>
				<title>Galleries - Sled Photographer Demo &bull; React Multipurpose Template</title>
				<meta name="keywords" content="Photographer" />
			</Helmet>

			<HeaderPage
				pageName={'Galleries'}
				subtitle={'my passion'}
				icon={<FontAwesomeIcon icon={solid('images')} className="badge-icon" />}
			/>

			<motion.main
				className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h1 className="title2">Discover my photographic works</h1>
					<h2 className="title3 subtitle mb-2">Capturing the Beauty of the World</h2>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

					<div className="galleries-grid">
						{galleries.map(gallery =>
							<Link to={`/galleries/${gallery.id}`} key={gallery.id}>
								<article>
									<img
										src={gallery.images[0].src}
										alt={gallery.name + ' - sled photographer react template'}
									/>
									<h2>{gallery.name}</h2>
								</article>
							</Link>
						)}
					</div>
				</section>

				<CallToAction
					title={<>Discover all my <span className="txt-secondary">courses and workshops</span>!</>}
					text="Learn from professional photographers and stay up to date on the latest photography features and techniques"
					toPage="/workshop"
					label="DISCOVER NOW!"
				/>

				<section>
					<h2 className="txt-center mb-2">FAQs</h2>

					{faqs.map((faq, idx) =>
						<Faq
							key={idx}
							question={faq.question}
							answer={faq.answer}
						/>
					)}
				</section>
			</motion.main>

			<Footer />
		</>
	);
}