import sponsor1 from '../imgs/sponsor/sponsor1.png';
import sponsor2 from '../imgs/sponsor/sponsor2.png';
import sponsor4 from '../imgs/sponsor/sponsor4.png';
import sponsor5 from '../imgs/sponsor/sponsor5.png';


export default [
	{
		src: sponsor4,
		alt: 'Sponsor Name - Sled Photographer'
	},
	{
		src: sponsor2,
		alt: 'Sponsor Name - Sled Photographer'
	},
   {
      src: sponsor1,
      alt: 'Sponsor Name - Sled Photographer'
   },
   {
      src: sponsor5,
      alt: 'Sponsor Name - Sled Photographer'
   }
];