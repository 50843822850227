import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import workshops from '../data/workshop.js';

import HeaderPage from '../components/HeaderPage';
import Sponsors from '../components/Sponsors.js';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import ErrorPage from './ErrorPage';

import bgworkshop from '../imgs/bg/bg-workshop.webp';

export default function WorkshopDetails() {
   const { workshopUrl } = useParams(),
			workshop = workshops.filter(workshop => workshop.id === workshopUrl)[0];

   if (workshop) {
      return (
         <>
            <Helmet>
               <title>{workshop.name} - Workshop - Sled Photographer Demo &bull; React Multipurpose Template</title>
					<meta name="keywords" content="Photographer" />
            </Helmet>

            <HeaderPage
					bg={bgworkshop}
               pageName={workshop.name}
               subtitle={'workshop'}
               icon={<FontAwesomeIcon icon={solid('person-chalkboard')} className="badge-icon" />}
            />

            <motion.main
               className="container"
               initial={{ opacity: 0, y: '100%' }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: .8, ease: 'easeInOut' }}
               exit={{ opacity: 0, y: '100%' }}
            >
               <section className="grid">
                  <img
                     src={workshop.src}
                     alt={workshop.name + ' - Sled Photographer template'}
                  />
                  <article className="relative">
                     <h1 className="txt-primary">{workshop.name}</h1>
                     <h2 className="txt-secondary mb-2">{workshop.date}</h2>

							{workshop.soldout &&
								<span class="relative label mb-2">SOLDOUT</span>
							}
							
                     <p className="mb-2">{workshop.text}</p>

							<Link to="/contact-me" className="badge">BOOK NOW</Link>
                  </article>
               </section>

               <Sponsors />

					<CallToAction
						title={<>Book now this <span className="txt-secondary">workshops</span>!</>}
						text="Learn from professional photographers and stay up to date on the latest photography features and techniques"
						toPage="/contact-me"
						label="BOOK NOW!"
					/>
            </motion.main>

            <Footer />
         </>
      );
   } else {
      return <ErrorPage />
   }
}