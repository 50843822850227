import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ContactInfo from './ContactInfo';
import logo from '../imgs/logo.svg';
import Sponsors from './Sponsors';

export default function Footer() {
	const thisYear = new Date().getFullYear();
	return (
		<motion.footer
			initial={{ opacity: 0, y: '100%' }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 1, ease: 'easeInOut' }}
			exit={{ opacity: 0, y: '100%' }}
		>
			
			<div className="container txt-left">
				<div className="grid">
					<div className="flogo">
						<img src={logo} alt="Sled logo" />
						<h3>Capture the moment</h3>
						<nav>
							<ul className="inline">
								<li><Link to ='/'>Home</Link></li>
								<li><Link to='/about-me'>About Me</Link></li>
								<li><Link to ='/galleries'>Galleries</Link></li>
								<li><Link to ='/galleries/macro'>Gallery</Link></li>
								<li><Link to ='/workshop'>Workshop</Link></li>
								<li><Link to ='/workshop/paris'>Workshop Details</Link></li>
								<li><Link to ='/contact-me'>Contact Me</Link></li>
								<li><Link to ='/faqs'>Faqs</Link></li>
							</ul>
						</nav>
					</div>

					<ContactInfo />
				</div>
				
				<Sponsors />
			</div>
			
			<p>&copy; Sled Photographer {thisYear}. All Rights Reserved.</p>
			<p>Developed by <a href="https://sled.travolgi.com">Travolgi</a>.</p>
		</motion.footer>
	);
}