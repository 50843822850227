import './HomeNav.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useVisible from '../hooks/useVisible';
import useWindowWidth from '../hooks/useWindowWidth';
import logoMobile from '../imgs/logo-mobile.svg';
import logo from '../imgs/logo.svg';


export default function HomeNav() {
   const visible = useVisible(15),
         windowWidth = useWindowWidth();

   const mainNav = useRef();
   
   const handleMouseMove = e => {
      const container = mainNav.current,
            scrollSpeed = .8;

      if (container) {
         const mouseX = e.clientX;
         const containerRect = container.getBoundingClientRect();
         const containerCenterX = containerRect.left + containerRect.width / 2;
         const distanceFromCenter = mouseX - containerCenterX;
         container.scrollLeft += distanceFromCenter * scrollSpeed;
      }
   };
   
   return (
      <>
         <Helmet>
				<title>Sled Photographer Demo &bull; React Multipurpose Template</title>
				<meta name="keywords" content="Photographer" />
			</Helmet>

         <motion.header
            className="logo"
            initial={{ opacity: 0, y: '100%' }}
				animate={visible ? {opacity: 0, y: -25} : {opacity: 1, y: 0} }
				transition={{ duration: .3, ease: 'easeInOut' }}
         >
            <div className="top-shadow" />
            <img
               src={windowWidth > 1080 ? logo : logoMobile}
               alt="Sled logo"
            />
            <h2>Capture the moment</h2>
         </motion.header>

         <motion.main
            className="slider"
            ref={mainNav}
            onMouseMove={handleMouseMove}
            initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .6, ease: 'easeInOut' }}
         >
            <nav id="navbar">
               <ul>
                  <li>
                     <Link to='/about-me'>
                        <section>
                           <h3>About Me</h3>
                           <p className="badge">George Smith</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('camera')} />
                           <p>My story and my experiences</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/galleries'>
                        <section>
                           <h3>Galleries</h3>
                           <p className="badge">my passion</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('images')} />
                           <p>Discover my photographic works</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/workshop'>
                        <section>
                           <h3>Workshop</h3>
                           <p className="badge">photography courses</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('person-chalkboard')} />
                           <p>Learn from professional photographers</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/contact-me'>
                        <section>
                           <h3>Contact Me</h3>
                           <p className="badge">get in touch</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('paper-plane')} />
                           <p>write to me or book your service!</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/faqs'>
                        <section>
                           <h3>Faqs</h3>
                           <p className="badge">Frequent questions</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('question')} />
                           <p>Everything you need to know!</p>
                        </footer>
                     </Link>
                  </li>
               </ul>
            </nav>
         </motion.main>
      </>
   );
}