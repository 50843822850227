import './Workshop.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import workshops from '../data/workshop.js';

import HeaderPage from '../components/HeaderPage';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';

import bgworkshop from '../imgs/bg/bg-workshop.webp';

export default function Workshop() {  
   return (
      <>
			<Helmet>
				<title>Workshop - Sled Photographer Demo &bull; React Multipurpose Template</title>
				<meta name="keywords" content="Photographer" />
			</Helmet>

         <HeaderPage
				bg={bgworkshop}
				pageName={'Workshop'}
				subtitle={'photography courses'}
				icon={<FontAwesomeIcon icon={solid('person-chalkboard')} className="badge-icon" />}
			/>

			<motion.main
				className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>

				<section className="txt-center">
					<h1 className="title2">Learn from professional photographers</h1>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

					<div className="workshop-grid">
						{workshops.map(workshop =>
							<Link to={`/workshop/${workshop.id}`} key={workshop.id}>
								<article className="relative">
									{workshop.soldout &&
										<span class="label">SOLDOUT</span>
									}
									<img
										src={workshop.src}
										alt={workshop.name + ' - sled photographer react template'}
									/>
									<h2>{workshop.name}</h2>
									<h3>{workshop.date}</h3>
									<p>{workshop.text.slice(0,50) + '...'}</p>
									<Link to={`/workshop/${workshop.id}`} className="badge mt-1">Discover now</Link>
								</article>
							</Link>
						)}
					</div>
				</section>


				<CallToAction
					title={<>Book now my <span className="txt-secondary">courses and workshops</span>!</>}
					text="Learn from professional photographers and stay up to date on the latest photography features and techniques"
					toPage="/contact-me"
					label="CONTACT NOW!"
				/>
			</motion.main>

			<Footer />
      </>
   );
}