import './CallToAction.css';
import { Link } from 'react-router-dom';

export default function CallToAction({ title, text, toPage, label, mb0 = false }) {
   return (
      <section className={mb0 === false ? 'cta' : 'cta mb-0' }>
			<div>
				<h2>{title}</h2>
				<p>{text}</p>
			</div>
         <Link to={toPage} className="badge">{label}</Link>
      </section>
   );
}