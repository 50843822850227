import './Sponsors.css';
import sponsors from '../data/sponsor';

export default function Sponsors() {   
   return (
		<section className="mb-2">
			<h3 className="subtitle txt-center mb-2">Sponsor & Partnership</h3>
			<div className="sponsor">
				{sponsors.map((sponsor, idx) => 
					<img
						key={idx}
						src={sponsor.src}
						alt={sponsor.alt + ' - Sponsor & Partnership - Sled React Template'}
						title={sponsor.alt + ' - Sponsor & Partnership - Sled React Template'}
					/>
				)}
			</div>
		</section>
   );
}