import { useState } from 'react'
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function BuyNowBtn() {
   const styleBtn = {
      zIndex: 999999,
      position: 'fixed',
		top: '10%',
		right: '-9rem',
		paddingInline: '.8rem',
      borderRadius: '0',
      borderRight: '0',
      paddingRight: '2rem',
      fontSize: '1.3rem',
   };
   
	const [btnPosition, setBtnPosition] = useState(styleBtn.right);
   
   return (
      <motion.a
         href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sled_react_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi"
         className="badge pulse"
			style={{ ...styleBtn, right: btnPosition }}
			onMouseOver={() => setBtnPosition('0')}
			onMouseOut={() => setBtnPosition(styleBtn.right)}

         initial={{ opacity: 0, x: '100%' }}
         animate={{ opacity: 1, x: 0 }}
         transition={{ duration: .8, ease: 'easeIn' }}
      >
         <FontAwesomeIcon icon={solid('cart-shopping')} />
         <span style={{ marginLeft: '.5rem' }}>BUY NOW</span>
      </motion.a>
   );
}