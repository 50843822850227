import './HeaderPage.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function HeaderPage({ bg, pageName, subtitle, icon }) {
   return (
      <motion.header
         className="top-section"
         style={bg ? { backgroundImage: `url(${bg})` } : null}

         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: .5, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         <div className="top-shadow" />
         <Link to="/" className="goback">
            <span className="sr-only">Home</span>
         </Link>

         <section>
            <p className="title">{pageName}</p>
            <p className="badge">{subtitle}</p>
         </section>

         {icon}
      </motion.header>
   );
}