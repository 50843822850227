import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomeNav from './pages/HomeNav';
import AboutMe from './pages/AboutMe';
import Workshop from './pages/Workshop';
import WorkshopDetails from './pages/WorkshopDetails';
import Galleries from './pages/Galleries';
import Gallery from './pages/Gallery';
import ContactMe from './pages/ContactMe';
import Faqs from './pages/Faqs';
import ErrorPage from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';
import BuyNowBtn from './components/BuyNowBtn';


export default function App() {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location]);

	return (
		<AnimatePresence>
			<Routes location={location} key={location.pathname}>
				<Route path='/' element={<HomeNav />} />
				<Route path='about-me' element={<AboutMe />} />
				<Route path='workshop' element={<Workshop />} />
				<Route path='workshop/:workshopUrl' element={<WorkshopDetails />} />
				<Route path='galleries' element={<Galleries />} />
				<Route path='galleries/:galleryUrl' element={<Gallery />} />
				<Route path='contact-me' element={<ContactMe />} />
				<Route path='faqs' element={<Faqs />} />
				<Route path='/*' element={<ErrorPage />} />
			</Routes>

			<ScrollToTop />
			<BuyNowBtn />
		</AnimatePresence>
	);
}