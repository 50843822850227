import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import galleries from '../data/galleries.js';

import HeaderPage from '../components/HeaderPage.js';
import GalleryBox from '../components/Gallery.js';
import CallToAction from '../components/CallToAction.js';
import Footer from '../components/Footer.js';
import ErrorPage from './ErrorPage.js';

export default function Gallery() {
   const { galleryUrl } = useParams(),
			gallery = galleries.filter(gallery => gallery.id === galleryUrl)[0];

   if (gallery) {
      return (
         <>
            <Helmet>
               <title>{gallery.name} - Galleries - Sled Photographer Demo &bull; React Multipurpose Template</title>
					<meta name="keywords" content="Photographer" />
            </Helmet>

            <HeaderPage
               pageName={gallery.name}
               subtitle={'galleries'}
               icon={<FontAwesomeIcon icon={solid('images')} className="badge-icon" />}
            />

            <motion.main
               initial={{ opacity: 0, y: '100%' }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: .8, ease: 'easeInOut' }}
               exit={{ opacity: 0, y: '100%' }}
            >

					<GalleryBox
						imgs={gallery.images}
					/>

					<div className="container">
						<CallToAction
							title={<>Discover all my <span className="txt-secondary">courses and workshops</span>!</>}
							text="Learn from professional photographers and stay up to date on the latest photography features and techniques"
							toPage="/workshop"
							label="DISCOVER NOW!"
						/>
					</div>
            </motion.main>

            <Footer />
         </>
      );
   } else {
      return <ErrorPage />
   }
}