import './AboutMe.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useWindowWidth from '../hooks/useWindowWidth';

import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import CallToAction from '../components/CallToAction';
import Sponsors from '../components/Sponsors.js';
import Footer from '../components/Footer';

import about from '../imgs/bg/bg-about.webp';
import george from '../imgs/george.webp';
import experience from '../imgs/experience.webp';
import mission from '../imgs/mission.webp';
import beauty from '../imgs/beauty.webp';
import award from '../imgs/award.webp';
import review1 from '../imgs/reviews/review1.jpg';
import review3 from '../imgs/reviews/review3.jpg';
import review4 from '../imgs/reviews/review4.jpg';
import review5 from '../imgs/reviews/review5.jpg';


export default function AboutMe() {
	const isMobile = useWindowWidth() < 1150;
	return (
		<>
			<Helmet>
				<title>About Me - Sled Photographer Demo &bull; React Multipurpose Template</title>
				<meta name="keywords" content="Photographer" />
			</Helmet>

			<HeaderPage
				bg={about}
				pageName={'About Me'}
				subtitle={'George Smith'}
				icon={<FontAwesomeIcon icon={solid('camera')} className="badge-icon" />}
			/>

			<motion.main
				className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="mb-2">
					<h1 className="title2 txt-center">My story and my experiences</h1>
					<h2 className="title3 subtitle txt-center mb-2">Hi, I'm George Smith, professional Photographer</h2>

					<div className="grid">
						<article>
							<h2 className="mb-2">Who would have thought it: <span className="txt-secondary">from passion to reality</span>!</h2>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
							<p className="mb-2">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
						</article>

						<article className="team-vertical">
							<img src={george} alt="George Smith photographer - Sled Photographer template" />
							<h2>George Smith</h2>
							<h3 className="subtitle">PRO Photographer</h3>
						</article>
					</div>
				</section>

				<section className="txt-center">
					<h3 className="subtitle mb-2">Why choose me?</h3>
					<div className="grid gtc-x3">
						<article>
							<i className="badge-icon mb-2">
								<FontAwesomeIcon icon={solid('earth-america')} />
							</i>
							<h2 className="txt-secondary">INTERNATIONAL TEACHER</h2>
							<p>Teacher and best international photographer recognized globally</p>
						</article>

						<article>
							<i className="badge-icon mb-2">
								<FontAwesomeIcon icon={solid('eye')} />
							</i>
							<h2 className="txt-secondary">BEST OPTICS SPONSOR</h2>
							<p>Official sponsor of the best brands of optics for photographers</p>
						</article>

						<article>
							<i className="badge-icon mb-2">
								<FontAwesomeIcon icon={solid('graduation-cap')} />
							</i>
							<h2 className="txt-secondary">COURSE & WORKSHOP COACH</h2>
							<p>Courses and workshops around the world for photographers of all levels</p>
						</article>
					</div>
				</section>

				<section>
					<div className="grid">
						<img src={experience} alt="Sled photographer template" />

						<article>
							<p className="title2 mb-2">My experiences</p>
							<ul className="mb-2">
								<li>1999 - Macenates eliqua dolorem</li>
								<li>2002 - Ut enim ad minim veniam</li>
								<li>2003 - Quis nostrud exercitation ullamco laboris</li>
								<li>2005 - Enim ad minim veniam exercitation</li>
								<li>2010 - Lorem dolorem et sie amiet</li>
								<li>2011 - Lorem dolorem amet cosenque</li>
								<li>2015 - Excepteur sint occaecat</li>
								<li>2018 - Sie cupidatat non proident</li>
								<li>2029 - Lorem dolorem macenate</li>
								<li>2020 - Et officia deserunt mollit </li>
								<li>2021 - Irure dolor in reprehenderit</li>
								<li>2023 - Consecu tema aliqa dorem dolorem</li>
							</ul>

							<Link to='/contact-me' className="badge">Contact me now!</Link>
						</article>
					</div>
				</section>

				<section className="grid mb-2">
					<Review
						img={review4}
						fullName="Marlon Brandom"
						role="Chef"
						stars={5}
						review="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
					/>
					<Review
						img={review5}
						fullName="Cristina Lored"
						role="Wedding"
						stars={5}
						review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor."
					/>
				</section>

				
				<Sponsors />


				<section className="grid">
					<article>
						<h2 className="mb-2">Capturing the <span className="txt-primary">Beauty of the World</span></h2>
						<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
					</article>

					<img
						src={beauty}
						alt="Sled photographer template"
					/>
				</section>

				<section className="grid p-0">
					{!isMobile &&
						<img
							src={mission}
							alt="Sled photographer template"
						/>
					}

					<article>
						<h2 className="mb-2">Mission: <span className="txt-primary">explore</span> and <span className="txt-secondary">capture</span> the world around me</h2>
						<p className="mb-2">Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
						<Link to='/contact-me' className="badge mb-2">Contact ME Now!</Link>

						
						{isMobile &&
							<img
								src={mission}
								alt="Sled photographer template"
								className="mt-1 mb-2"
							/>
						}
					</article>

				</section>

				<section className="grid mb-2">
					<article>
						<h2 className="mb-2">Awards of Excellence: <span className="txt-secondary">Awards Received</span>!</h2>
						<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
					</article>

					<img
						src={award}
						alt="Sled photographer template"
					/>
				</section>

				<section className="grid mb-2">
					<Review
						img={review1}
						fullName="Marlena Cherry"
						role="CEO"
						stars={5}
						review="Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repreh enderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
					/>
					<Review
						img={review3}
						fullName="David Godman"
						role="Photographer"
						stars={5}
						review="Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor."
					/>
				</section>

				<CallToAction
					title={<>Discover all my <span className="txt-secondary">courses and workshops</span>!</>}
					text="Learn from professional photographers and stay up to date on the latest photography features and techniques"
					toPage="/workshop"
					label="DISCOVER NOW!"
				/>
			</motion.main>

			<Footer />
		</>
	);
}